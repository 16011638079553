<template>
  <div @click="setValue()" id="btn-radio">
    <label><slot></slot></label>
    <input
      class="radio"
      type="radio"
      :value="value"
      ref="radio"
      :checked="value === model"
      @input="setValue"
    />
  </div>
</template>

<script>
export default {
  model: {
    prop: "model",
  },
  props: {
    value: [String, Boolean, Number],
    model: [String, Boolean, Number],
  },
  methods: {
    setValue() {
      this.$emit("input", this.$refs.radio.value);
    },
  },
};
</script>

<style scoped>
#btn-radio {
  background: #fefefe;
  height: 20px;
  padding: 18px 16px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;

  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  box-sizing: border-box;
  border-radius: 5px;
  transition: 0.5s;
  margin: 0.9rem 1.5em 0px 0px;
}
#btn-radio span {
  /* margin-right: 10px; */
}
#btn-radio:hover {
  background: #f0efef;
}

.radio {
  vertical-align: 2px;
}

label {
  width: 95%;
  font-family: "Inter", sans-serif;
  color: #626262;
}
</style>
