<template>
  <div>
    <small>{{ label }}</small>
    <div :tabindex="tabindex" class="custom-select" @blur="open = false">
      <div :class="{ open: open }" class="selected" @click="open = !open">
        {{ optionBuilder(selected) }}
      </div>
      <div :class="{ selectHide: !open }" class="items">
        <div
          v-for="(option, i) of options"
          :key="i"
          @click="
            selected = option;
            open = false;
            $emit('input', option);
          "
        >
          {{ optionBuilder(option) }}
        </div>
      </div>
    </div>
    <small v-if="required" class="hint">*Campo obrigatório</small>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    default: {
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    isGender: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    "option-builder": {
      type: Function,
      required: false,
      default: (option) => option,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0 && !this.isGender
        ? this.options[0]
        : null,
      open: false,
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },
};
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  font-family: "Inter", sans-serif;
  margin: 0.9rem 0px 0px 0px;
}

.custom-select .selected {
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;

  height: 35px;
  padding: calc((35px - 0.875rem) / 2) 16px;
  border-radius: 8px;

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  vertical-align: baseline;

  color: var(--gray-dark);
  background-color: #f3f3f4;
}

.custom-select .selected.open {
  border: 1px solid #7367f0;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: calc((100% - 5px) / 2);
  right: 16px;
  width: 0;
  height: 0;
  border: 5px solid;
  border-color: #7367f0 transparent transparent transparent;
}

.custom-select .items {
  color: #343a40;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;

  position: absolute;
  background-color: white;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  color: #343a40;
  padding: calc((35px - 0.875rem) / 2) 16px;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #7367f0;
  color: white;
}

.selectHide {
  display: none;
}
</style>
