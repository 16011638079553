<template>
  <div class="auth-data">
    <div :hidden="!activatingUser">
      <custom-loading />
    </div>
    <div :hidden="activatingUser">
      <h5>Complete seu cadastro na <strong>Inside Brasil</strong></h5>
    </div>
    <div :hidden="activatingUser">
      <text-field
        id="name"
        v-model="name"
        :value="name"
        label="Nome"
        placeholder="Nome"
        required
        type="text"
      />
      <text-field
        id="cpf"
        label="CPF"
        v-model="cpf"
        v-mask="'###.###.###-##'"
        placeholder="000.000.000 - 00"
        :textFieldError="isValidCPF ? `` : cpfError"
        required
      />
      <text-field
        id="date_nasc"
        v-model="birth_date"
        :value="birth_date"
        label="Data de nascimento"
        placeholder="Nome"
        required
        type="date"
      />

      <text-field
        id="area_actuation"
        v-model="profession"
        :value="profession"
        label="Área de atuação"
        placeholder="Área de atuação"
        required
        type="text"
      />

      <custom-select
        :label="'Renda mensal'"
        :option-builder="buildFamilyIncomeOption"
        :options="incomeOptions"
        :required="true"
        @input="onFamilyIncomeChanged"
      />

      <text-field
        id="tel"
        v-model="phone"
        type="tel"
        :value="phone"
        :textFieldError="isValidPhone ? `` : `Telefone já cadastrado`"
        placeholder="Telefone"
        v-mask="'(##) ##### - ####'"
        label="Celular"
        required
      />
      <custom-select
        :label="'Gênero'"
        :option-builder="buildGender"
        :options="genderOptions"
        :required="true"
        @input="onGenderChanged"
      />
      <div>
        <div class="form-control">
          <span class="text-label">Sexo</span>
        </div>
        <div class="d-flex align-center flex-start">
          <radio-item v-model="sex" class="col-6" name="sex" value="Male">
            Masculino
          </radio-item>

          <radio-item v-model="sex" class="col-6" name="gender" value="Female">
            Feminino
          </radio-item>
        </div>
        <div>
          <small class="hint">*Campos obrigatórios</small>
        </div>
      </div>
      <!-- <text-field
        id="friendly_invitation_code"
        label="Código de indicação"
        v-model="friendly_invitation_code"
        type="text"
        :value="friendly_invitation_code"
        placeholder="Caso tenha, digite o código de indicação"
        :textFieldError="invitationsCodeVerification"
      /> -->
      <text-field
        id="friendly_invitation_code"
        label="Código de indicação"
        v-model="friendly_invitation_code"
        placeholder="Caso tenha, digite o código de indicação"
        :textFieldError="isValidInvitationCode ? `` : invitationCodeError"
      />
      <div class="mt-10">
        <custom-button
          :disabled="!isValidSubmitForm"
          :loading="loading"
          color="primary"
          icon
          text="Confirmar"
          @click="advancedStep()"
          ><div slot="icon">
            <span class="material-icons material-icons-outlined">
              arrow_forward
            </span>
          </div></custom-button
        >
        <custom-button
          color="primary"
          outlined
          text="Cancelar"
          @click="$router.push({ name: 'login' })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import TextField from "@/components/Form/TextField/index.vue";
import Radio from "@/components/Form/Radio/index.vue";
import CustomButton from "@/components/Button/";
import CustomSelect from "@/components/Form/CustomSelect";
import CustomLoading from "@/components/Loading/Loading";
import auth from "@/api/services/auth.js";
import searchs from "@/api/services/searchs.js";
import users from "@/api/services/user.js";
import { cpf } from "cpf-cnpj-validator";

export default {
  name: "user-data",
  directives: { mask },
  components: {
    "text-field": TextField,
    "radio-item": Radio,
    "custom-select": CustomSelect,
    CustomButton,
    "custom-loading": CustomLoading,
  },
  mounted() {
    this.onMounted();
  },
  data() {
    return {
      name: null,
      sex: null,
      selectedGenderId: null,
      birth_date: new Date(),
      cpf: null,
      profession: null,
      phone: null,
      isValidCPF: true,
      isValidInvitationCode: true,
      loading: false,
      isValidPhone: true,
      selectedIncomeOptionId: null,
      incomeOptions: [],
      genderOptions: [],
      cpfError: null,
      activatingUser: false,
      invitationCodeError: null,
      friendly_invitation_code: null,
    };
  },
  watch: {
    cpf(newValue) {
      if (newValue && newValue.length === 14) {
        this.isValidCPF = cpf.isValid(newValue);
      }
    },
  },
  computed: {
    isValidSubmitForm() {
      return (
        this.name &&
        this.sex &&
        this.birth_date &&
        this.cpf &&
        this.profession &&
        this.phone &&
        this.isValidCPF &&
        this.isValidInvitationCode &&
        this.selectedIncomeOptionId &&
        this.selectedGenderId &&
        this.friendly_invitation_code
      );
    },
    // invitationsCodeVerification() {
    //   if (
    //     this.friendly_invitation_code !== null &&
    //     this.friendly_invitation_code.length === 6
    //   ) {
    //     const errorMessage = "Código Inválido";
    //     if (errorMessage === "Código Inválido") {
    //       return "Código de indicação inválido";
    //     } else {
    //       return null;
    //     }
    //   }
    //   return null;
    // },
  },

  methods: {
    async onMounted() {
      const { token } = this.$route.params;
      if (token) {
        this.activatingUser = true;

        await auth.confirmAuth(token);

        this.$router.push({ name: "login" });
        this.$toast.success(
          "Usuário ativado com sucesso! Faça login para continuar a criação da sua conta"
        );
      } else {
        this.activatingUser = false;
        await Promise.all([this.getFamilyIncomes(), this.getGenderOptions()]);
      }
    },
    onFamilyIncomeChanged(option) {
      this.selectedIncomeOptionId = option?.id;
    },
    buildFamilyIncomeOption(option) {
      return option?.income ?? "Selecione sua renda mensal";
    },
    onGenderChanged(option) {
      this.selectedGenderId = option?.id;
    },
    buildGender(option) {
      return option?.gender ?? "Selecione seu gênero";
    },
    async validateOnForm() {
      return (
        this.sex &&
        this.name &&
        this.birth_date &&
        this.cpf &&
        this.profession &&
        this.phone &&
        this.isValidCPF &&
        this.isValidInvitationCode &&
        this.selectedIncomeOptionId &&
        this.friendly_invitation_code
      );
    },
    async getFamilyIncomes() {
      try {
        const apiResponse = await searchs.getFamilyIncomes();
        this.incomeOptions = apiResponse.data.familyIncomes;
      } catch (err) {
        this.$toast.error(
          "Falha ao carregar as opções de renda, por favor atualize a página e tente novamente"
        );
      }
    },
    async getGenderOptions() {
      try {
        const apiResponse = await users.getAvailableGenders();
        this.genderOptions = apiResponse.data.genders;
      } catch (err) {
        this.$toast.error(
          "Falha ao carregar as opções de gênero, por favor atualize a página e tente novamente"
        );
      }
    },
    async sendDataValidator() {
      const data = {
        cpf: this.cpf,
        phone: this.phone,
        birth_date: this.birth_date,
        friendly_invitation_code: this.friendly_invitation_code,
      };
      if (cpf.isValid(this.cpf)) {
        try {
          await auth.isValidateUser(data);
          return true;
        } catch (error) {
          const errorMessage = JSON.parse(error.response.data.message) || "";

          if (errorMessage !== "") {
            errorMessage.map((error) => {
              if (error.field === "cpf") {
                this.isValidCPF = false;
                this.cpfError = error.message;
              }
              if (error.field === "phone") {
                this.isValidPhone = false;
              }
              if (error.field === "friendly_invitation_code") {
                this.isValidInvitationCode = false;
                this.invitationCodeError = error.message;
              }
            });
            this.loading = false;
          }
          if (errorMessage === "CPF already in use") {
            this.isValidCPF = false;
            this.cpfError = "CPF já cadastrado";
          }

          if (errorMessage === "Código Inválido") {
            this.isValidInvitationCode = false;
            this.invitationCodeError = "Código de indicação inválido";
          }

          if (errorMessage.toLowerCase().includes("cpf")) {
            this.isValidCPF = false;
          }
          if (errorMessage.toLowerCase().includes("phone")) {
            this.isValidPhone = false;
          }
          if (errorMessage.toLowerCase().includes("Phone")) {
            this.isValidPhone = false;
          }
        }
        return false;
      } else {
        this.isValidCPF = false;
        this.cpfError = "CPF inválido";
        return false;
      }
    },
    async advancedStep() {
      this.loading = true;

      const isAnyDataAlreadyRegistered = await this.sendDataValidator();
      const isFormValid = this.validateOnForm();

      if (isAnyDataAlreadyRegistered && isFormValid) {
        await this.$router.push({
          name: "address",
          params: {
            data: {
              name: this.name,
              cpf: this.cpf,
              birth_date: this.birth_date,
              sex: this.sex,
              profession: this.profession,
              family_income_id: this.selectedIncomeOptionId,
              gender_id: this.selectedGenderId,
              phone: this.phone,
              who_indicated: this.friendly_invitation_code,
              friendly_invitation_code: this.friendly_invitation_code,
            },
          },
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.heading {
  color: #0d0c22;
  vertical-align: baseline;
  font-size: 1rem;
}
.text-label {
  font-size: 0.875rem;
}
</style>
